<template>
	<div class='container'>
		<div class="btns">
			<el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
			<span style="margin:10px 30px; color:#fff; font-weight: bold; ">{{ pageNum }} / {{ pageTotalNum }}</span>
			<el-button type="primary" size="mini" @click="nextPage">下一页<i
					class="el-icon-arrow-right el-icon--right"></i></el-button>
		</div>
		<div class="pdfBox" ref="pdf">
			<pdf :page="pageNum" :src="url" @progress="loadedRatio = $event" @num-pages="pageTotalNum=$event"></pdf>		
		</div>
		


		


	</div>
</template>




<script>
	import pdf from 'vue-pdf'

	export default {
		name: 'Pdf',
		components: {
			pdf,
		},
		data() {
			return {
				url: '',
				pageNum: 1,
				pageTotalNum: 1, // 总页数
				loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
			}
		},
		created(){
			 console.log('地址',this.$route.query.url);
			 this.url = this.$route.query.url
		},
		methods: {
			// 上一页
			prePage() {
				let page = this.pageNum
				page = page > 1 ? page - 1 : this.pageTotalNum
				this.pageNum = page
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},

			// 下一页
			nextPage() {
				let page = this.pageNum
				page = page < this.pageTotalNum ? page + 1 : 1
				this.pageNum = page;
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},
			goTop(){
			     this.$refs['pdf'].scrollTop = 0;
			}
			
		}
	}
</script>
<style lang="scss" scoped>
	

	.pdfBox {
		overflow-y: auto;
		height:1600px;
		width: 1000px;

		margin: 0 auto;
	}
	
	.btns{
		margin: 10px auto 10px;
		height:40px;
		width:500px;
		text-align: center;
		
	}
	

</style>
